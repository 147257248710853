import axios from "axios";
import { Logging, SeverityLevel } from "../../../utils/logging";
import { AuthServiceInstance } from "../../../authentication/authService";

export interface IReviewSourceDetails {
  reviewUrl: string;
  responseUrl: string;
}

export interface AIResponses {
  greeting: string;
  responses: string[];
}

export const fetchVisitReportReviewSourceDetailsApi = async (
  clientId: number,
  visitId: number
): Promise<IReviewSourceDetails | null> => {
  let result: IReviewSourceDetails | null = null;

  const response = await axios
    .get<IReviewSourceDetails>(
      `${process.env.REACT_APP_HUB_API_BASEURL}VisitReport/Client/${clientId}/GetReviewSourceDetails/${visitId}`
    )
    .catch((error) => {
      const e = new Error();
      if (error) {
        e.message = error.toString();
      }

      Logging.captureError(
        "fetchVisitReportReviewSourceDetailsApi",
        e,
        SeverityLevel.Error
      );
    });

  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const fetchAIResponsesApi = async (
  name: string,
  message: string
): Promise<AIResponses | null> => {
  const clientId = await AuthServiceInstance.getClientId();

  let result: AIResponses | null = null;
  const data = { reviewerName: name, review: message };

  const response = await axios
    .post(
      `${process.env.REACT_APP_RESPONSES_API_BASEURL}client/${clientId}/generate`,
      data
    )
    .catch((error) => {
      const e = new Error();
      if (error) {
        e.message = error.toString();
      }
      Logging.captureError("fetchResponsesApi", e, SeverityLevel.Error);
    });

  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
