import React, { useEffect, useRef, useState } from "react";
import { ReviewResponseStyles } from "../styles/reviewResponseStyles";
import { Grow } from "@material-ui/core";
import { AIResponses } from "../state/api";

type Props = { responses: AIResponses; onSelected: (message: string) => void };

const AIResponseContainer = ({ responses, onSelected }: Props) => {
  const classes = ReviewResponseStyles();
  const [previewMessage, setPreviewMessage] = useState<number | undefined>(
    undefined
  );
  const [arrowPosition, setArrowPosition] = useState<number>(0);
  const row = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewMessage === undefined || !row.current) return;
    const buttons = row.current?.querySelectorAll(
      `.${classes.AIResponseButton}`
    );

    if (buttons?.[previewMessage]) {
      const buttonRect = buttons[previewMessage].getBoundingClientRect();
      const rowRect = row.current?.getBoundingClientRect();
      const position = (buttonRect.left + buttonRect.right) / 2 - rowRect.left;
      setArrowPosition(position);
    }
  }, [previewMessage]);

  return (
    <div className={classes.AIResponsesContainer}>
      {previewMessage !== undefined && (
        <Grow in={previewMessage !== undefined}>
          <div
            className="message-preview"
            style={{ "--option": `${arrowPosition}px` } as React.CSSProperties}
          >
            {responses.responses[previewMessage]}
          </div>
        </Grow>
      )}
      <div className={classes.AIResponsesRow} ref={row}>
        {responses.responses.map((message, i) => (
          <div className={classes.AIResponse}>
            <div
              className={classes.AIResponseButton}
              key={message}
              onClick={() => onSelected(responses.greeting + "\n\n" + message)}
              onMouseOver={() => setPreviewMessage(i)}
              onMouseLeave={() => setPreviewMessage(undefined)}
            >
              {message}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AIResponseContainer;
